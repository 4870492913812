import './Impressum.css'

export const Name = "Franziska-Schmitz Straße Vermögensverwaltung GmbH & Co. KG";
function Impressum() {
    return (
        <div className="container-fluid text-center my-5">
            <div className="row text-start">
                <div className="col-4"/>
                <div className="col-4">
                    <div className="container Impressum-Font">
                        <div className="col py-5">
                            <h1 className="Impressum-H"><p><strong>IMPRESSUM</strong></p></h1>
                        </div>
                        <div className="col">
                            <h3 className="Impressum-SubH"><p>Betreiber der Webseite</p></h3>
                            <h6 className="fw-bold"><p>{ Name }</p></h6>
                            <p>Eichenhof 1, 85406 Zolling</p>
                            <br/><br/>
                            <p>Kommanditgesellschaft mit Sitz in Zolling <br/>
                                Registergericht: Amtsgericht München <br/>
                                Handelsregisternummer: HRA 109415</p>
                            <br/>
                            <h6><strong><p>Persönlich haftende Gesellschafterin</p></strong></h6>
                            <p>Franziska-Schmitz Straße Verwaltung GmbH<br/>
                                mit Sitz in Zolling <br/>
                                Registergericht: Amtsgericht München <br/>
                                Handelsregisternummer: HRB 216851 <br/>
                                Geschäftsführer: Felix Blum, Florian Blum</p>
                        </div>
                        <div className="col py-5">
                            <h3 className="Impressum-SubH"><p>Datenschutz</p></h3>
                            <p>Unsere <a href="/datenschutz" className="Impressum-L">Datenschutzerklärung</a> gibt Ihnen einen
                                Überblick darüber, wie wir den Schutz Ihrer personenbezogenen Daten gewährleisten und
                                welche Art von Daten zu welchem Zweck erhoben werden.</p>
                        </div>
                    </div>
                </div>
                <div className="col-4"/>
            </div>
        </div>
    );
}

export default Impressum;
