import Header from "./WebpageComponents/Header";
import Footer from "./WebpageComponents/Footer";
import {Route, Routes} from "react-router-dom";
import Home from "./WebpageComponents/pages/Home";
import Impressum from "./WebpageComponents/pages/Impressum";
import Datenschutz from "./WebpageComponents/pages/Datenschutz";
import React from "react";
import './App.css';

function App() {
    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/datenschutz" element={<Datenschutz/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

export default App;