import React from "react";
import './Home.css';
import {Image} from "react-bootstrap";
import HubspotForm from 'react-hubspot-form';

// Importing images from files (Nymphenburg)
import N_Innen1 from '../../Images/Nymphenburg/neuhausen-nymphenburg-5.webp';
import N_Innen2 from '../../Images/Nymphenburg/neuhausen-nymphenburg-6.webp';
import N_Innen3 from '../../Images/Nymphenburg/neuhausen-nymphenburg-7.webp';
import N_Innen4 from '../../Images/Nymphenburg/neuhausen-nymphenburg-8.webp';
import N_Aussen1 from '../../Images/Nymphenburg/neuhausen-nymphenburg-1.webp';
import N_Aussen2 from '../../Images/Nymphenburg/neuhausen-nymphenburg-2.webp';
import N_Aussen3 from '../../Images/Nymphenburg/neuhausen-nymphenburg-3.webp';
import N_Aussen4 from '../../Images/Nymphenburg/neuhausen-nymphenburg-4.webp';

// Importing images from files (Aubing)
import A_Aussen1 from '../../Images/Aubing/aubing-1.webp';
import A_Aussen2 from '../../Images/Aubing/aubing-2.webp';
import A_Aussen3 from '../../Images/Aubing/aubing-3.webp';
import A_Aussen4 from '../../Images/Aubing/aubing-4.webp';

import Makler from '../../Images/heiner-ortmann.webp';
// Variables for descriptions
export const Wohnung1 = "Franziska-Schmitz-Straße, Neuhausen-Nymphenburg",
    Wohnung2 = "Freihamer Weg, Aubing",
    Wohnung1Descr = "Wohnungen zur Miete zwischen Hirschgarten und Rotkreuzplatz.",
    Wohnung2Descr = "Wohnungen zur Miete nach München-Modell für Mieter - „Wohnen in München VI“.",
    Heading1 = "Mietwohnungen in München";


function Home() {
    return (
        <div className="App w-100 h-100 m-4 Home">
            <div className="container-fluid">
                <div className="row">
                    <div className="col p-5">
                        <h1 className="fw-bold">{Heading1}</h1>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-1">
                    <div className="col p-5">
                        <h2 className="fw-bold">{Wohnung1}</h2>
                        <p><strong>{Wohnung1Descr}</strong></p>
                        <div className="row row-cols-2 row-cols-md-4 text-center">
                            <div className="col">
                                <Image src={N_Innen1} alt="Bild 1 von Innen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={N_Innen2} alt="Bild 2 von Innen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={N_Innen3} alt="Bild 3 von Innen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={N_Innen4} alt="Bild 4 von Innen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={N_Aussen1} alt="Bild 1 von Außen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={N_Aussen2} alt="Bild 2 von Außen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={N_Aussen3} alt="Bild 3 von Außen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={N_Aussen4} alt="Bild 4 von Außen" thumbnail/>
                            </div>
                        </div>
                    </div>
                    <div className="col p-5">
                        <h2 className="fw-bold">{Wohnung2}</h2>
                        <p><strong>{Wohnung2Descr}</strong></p>
                        <div className="row row-cols-2 row-cols-md-4 text-center">
                            <div className="col">
                                <Image src={A_Aussen1} alt="Bild 1 von Aussen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={A_Aussen2} alt="Bild 2 von Aussen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={A_Aussen3} alt="Bild 3 von Aussen" thumbnail/>
                            </div>
                            <div className="col">
                                <Image src={A_Aussen4} alt="Bild 4 von Aussen" thumbnail/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid m-4">
                <h3 className="fw-bold">Ich suche folgende Mietwohnung?</h3>
                <div className="row">
                    <div className="col-lg-8 p-3">
                        <HubspotForm
                            region='eu1'
                            portalId='145019169'
                            formId='70322b85-851f-4869-9972-d7373f8e6603'
                            onSubmit={() => console.log('Submit!')}
                            onReady={() => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                        />
                    </div>
                    <div className="col-lg-4 text-center m-auto ps-0">
                        <h6 className="fw-bold">Immobilien-Makler</h6>
                        <h3 className="fw-bold">Heiner Ortmann</h3>
                        <Image src={Makler} alt="Bild von Heiner Ortmann" width="60%" thumbnail/>
                        <p>0172 / 825 90 89<br /><a href={"mailto:info@immobilien-ortmann.de"}>info@immobilien-ortmann.de</a></p>
                        <p>Weitere Mietangebote auf</p>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item"><a href="https://www.tewobau.de/">tewobau.de</a></li>
                            <li className="list-group-item"><a href="https://www.toelzervv.de/">toelzervv.de</a></li>
                            <li className="list-group-item"><a
                                href="https://www.werksviertelvv.de/">werksviertelvv.de</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>);
}

export default Home;
