import './Footer.css'

function Footer() {
    return (
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <p className="col-md-4 mb-0 text-body-secondary">Mit <span style={{color: "red"}}>&hearts;</span> gemacht von <a href="https://www.sblum.de/">sblum</a></p>
            <ul className="nav col-md-4 justify-content-end">
                <li className="nav-item"><a href="/" className="nav-link px-2 text-body-secondary">Startseite</a></li>
                <li className="nav-item"><a href="/datenschutz" className="nav-link px-2 text-body-secondary">Datenschutz</a></li>
                <li className="nav-item"><a href="/impressum" className="nav-link px-2 text-body-secondary">Impressum</a></li>
            </ul>
        </footer>
    );
}

export default Footer;
