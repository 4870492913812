import './Header.css'
import logo from '../Images/logo.svg';

function Header() {
    return (
        <header className="App w-100 m-4">
            <div className="row">
                <div className="col">
                    <div className="text-center">
                        <a href="/">
                            <img src={logo} alt="Logo"/>
                        </a>   
                    </div>             
                </div>
            </div>           
        </header>
    );
}

export default Header;